import ToolSettingsExtension from './toolSettings'
import LegendButtonsExtension from './legendButtons'
import IndicatorSettingsExtension from './indicatorSettings'
import GridResizeExtension from './grid-resize'

export default [
  ToolSettingsExtension,
  LegendButtonsExtension,
  IndicatorSettingsExtension,
  GridResizeExtension
]
